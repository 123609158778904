import { useState } from 'react'
import { Link } from 'react-router-dom'

import { DomainKeywords } from './Reports/DomainKeywords'
import { KeywordSerpUrls } from './Reports/KeywordSerpUrls'
import { SerpUrlBacklinks } from './Reports/SerpUrlBacklinks'


export function Campaign({
    domain,
    keywords,
    links,
    availableKeywords,
    backlinks,
    availableBacklinks
}) {

    const [visibleTab, setVisibleTab] = useState(0)
    const [keyword, setKeyword] = useState('--all')
    const [url, setUrl] = useState('--all')

    function handleSelectKeyword(updatedKeyword) {
        setKeyword(updatedKeyword)
        setVisibleTab(2)
    }

    function handleSelectUrl(updatedUrl) {
        setUrl(updatedUrl)
        setVisibleTab(0)
    }

    const hasSerpLinks = !!links.length


    return <>
        <div className="container-lg d-flex align-items-center gap-4 mb-3">
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/campaigns">Campaigns</Link>
                    </li>
                </ol>
                <h1 className="breadcrumb-item active fs-1" aria-current="page">
                    {domain}
                </h1>
            </nav>

            <div className="ms-auto text-end lh-sm">
                {hasSerpLinks &&
                    <div>
                        <ul className="list-unstyled">
                            {!!backlinks &&
                                <li><strong>{backlinks.length}</strong> Backlinks</li>
                            }
                            <li><strong>{links.length}</strong> Links</li>
                            <li><strong>
                                {Object.getOwnPropertyNames(availableKeywords).length}
                            </strong> fetched Keywords
                            </li>
                        </ul>
                    </div>
                }
            </div>
        </div>

        {hasSerpLinks ?
            <ul className="nav nav-tabs px-3">
                <li className="nav-item">
                    <button type="button" onClick={() => {
                        setVisibleTab(0)
                        setUrl('--all')
                    }}
                    className={`nav-link ${(visibleTab === 0) ? 'active' : ''}`}
                    >
                        SERP URL Backlinks
                    </button>
                </li>
                <li className="nav-item ms-auto">
                    <button type="button" onClick={() => setVisibleTab(1)}
                        className={`nav-link ${(visibleTab === 1) ? 'active' : ''}`}
                    >
                        Domain Keywords
                    </button>
                </li>
                <li className="nav-item">
                    <button type="button" onClick={() => {
                        setVisibleTab(2)
                        setKeyword('--all')
                    }}
                    className={`nav-link ${(visibleTab === 2) ? 'active' : ''}`}
                    >
                        Keyword SERP URLs
                    </button>
                </li>
            </ul>
            :
            <h3 className="ms-3">Domain Keywords</h3>
        }


        {((visibleTab === 0) && hasSerpLinks) &&
            <SerpUrlBacklinks
                backlinks={backlinks}
                availableBacklinks={availableBacklinks}
                currentBacklink={url}
                handleSelectUrl={handleSelectUrl}
            />
        }

        {((visibleTab === 1) || !hasSerpLinks) &&
            <DomainKeywords
                keywords={keywords}
                availableKeywords={availableKeywords}
                handleSelectKeyword={handleSelectKeyword}
            />
        }

        {((visibleTab === 2) && hasSerpLinks) &&
            <KeywordSerpUrls
                links={links}
                keyword={keyword}
                availableKeywords={availableKeywords}
                availableBacklinks={availableBacklinks}
                handleSelectKeyword={handleSelectKeyword}
                handleSelectUrl={handleSelectUrl}
            />
        }

    </>
}
