import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Campaign } from '../components/Campaigns/Campaign'
import { getKeywordUrlMarkedWithDomain } from '../components/Campaigns/campaign-parsers'

import { Loader } from '../components/ui-blocks'

import { getDomainKeywords } from '../services'


export function CampaignDetail() {
    const [report, setReport] = useState(null)
    const { domain } = useParams()

    useEffect(() => {
        getDomainKeywords({ domain })
            .then(response => {
                const parsedReport = {
                    ...response,
                    links: getKeywordUrlMarkedWithDomain(response.links, domain)
                }

                setReport(parsedReport)
            })
    }, [domain])


    if (!report) {
        return <div className="text-center mt-5">
            <h4 className="mb-0">
                Fetching Ranked Keywords for <strong>{domain}</strong>
            </h4>
            <Loader fullWidth />
        </div>
    }


    return <Campaign
        domain={domain}
        keywords={report.keywords}
        links={report.links}
        availableKeywords={report.availableKeywords}
        backlinks={report.backlinks}
        availableBacklinks={report.availableBacklinks}
    />
}
