import { Table } from '../../Table'


export const DomainKeywords = ({ keywords, availableKeywords, handleSelectKeyword }) => {
    const rowsWithIndex = keywords.map((value, index) => ({ ...value, id: index + 1 }))

    const columns = [
        {
            field: 'id',
            title: '#',
            className: 'text-end'
        }, {
            field: 'keyword',
            title: 'Keyword',
            render: val => (val in availableKeywords) ?
                <button className="btn btn-link p-0 lh-1" onClick={() => handleSelectKeyword(val)}>
                    {val}
                </button> :
                val
        }, {
            field: 'competitionLevel',
            title: 'Competition Level'
        }, {
            field: 'serpItemTypes',
            title: 'SERP Types',
            render: val => val.join(', ')
        }, {
            field: 'keyword',
            key: 'links',
            title: 'Links',
            className: 'text-end',
            cellClassName: 'small font-monospace',
            render: val => (val in availableKeywords) ? availableKeywords[val] : null
        }, {
            field: 'seResultsCount',
            title: 'Results Count',
            className: 'text-end',
            cellClassName: 'small font-monospace'
        }
    ]


    return <Table columns={columns} rows={rowsWithIndex} />
}
