import { Table } from '../Table'


const dateFormatter = new Intl.DateTimeFormat('en-US', { dateStyle: 'medium', timeStyle: 'medium' })

const dateRendered = val => dateFormatter.format(new Date(val))


const columns = [
    {
        field: 'id',
        title: '#',
        className: 'text-end'
    }, {
        field: 'datetime',
        title: 'Type',
        render: dateRendered
    }, {
        field: 'type',
        title: 'Type'
    }, {
        field: 'term',
        title: 'Term'
    }, {
        field: 'item_count',
        title: 'Items',
        className: 'text-end',
        cellClassName: 'font-monospace small'
    }, {
        field: 'cost',
        title: 'Cost',
        className: 'text-end',
        cellClassName: 'font-monospace small'
    }
]


export const Cost = ({ transactions }) => {
    const rowsWithIndex = transactions.map((value, index) => ({ ...value, id: index + 1 }))

    return <Table columns={columns} rows={rowsWithIndex} />
}
