import React from 'react'
import PropTypes from 'prop-types'


export const Loader = ({ fullWidth = false, className = '', ...props }) => {

    const loader = (
        <div className={`spinner-grow ${className}`} role="status" {...props}>
            <span className="visually-hidden">Loading...</span>
        </div>
    )


    if (!fullWidth) {
        return loader
    }

    return (
        <div className="w-100 text-center py-5">
            {loader}
        </div>
    )
}


Loader.propTypes = {
    className: PropTypes.string,
    fullWidth: PropTypes.bool
}
