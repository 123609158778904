import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { getHistorial } from '../services'


export function Campaigns() {

    const [historial, setHistorial] = useState({})

    useEffect(() => {
        getHistorial()
            .then(result => setHistorial(result))
    }, [])


    return <div className="container-lg">
        <div className="d-flex gap-3 justify-content-between align-items-center mb-4">
            <h1>Campaigns</h1>
            <div>
                <Link to="/campaigns/create" className="btn btn-primary btn-sm">
                    Create Campaign
                </Link>
            </div>
        </div>

        {('rkw' in historial) && (
            <div className="d-flex flex-column gap-3">
                {historial.rkw.map(savedDomain => (
                    <Link key={savedDomain}
                        className="btn btn-light py-3 text-start border d-block fs-4 lh-1"
                        to={`/campaigns/${savedDomain}`}
                    >
                        {savedDomain}
                    </Link>
                ))}
            </div>
        )}

    </div>

}
