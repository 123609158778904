import { getHeaderCels, getRowCels } from './helpers'


export const Table = ({ rows, columns }) => (
    <table className="table table-sm align-middle table-hover table-striped position-relative lh-1">
        <thead className="sticky-top sticky-stacked-top">
            <tr className="bg-secondary">
                {getHeaderCels(columns)}
            </tr>
        </thead>
        <tbody>
            {rows.map(row => (
                <tr key={row.id}>
                    {getRowCels(row, columns)}
                </tr>
            ))}
        </tbody>
    </table>
)
