import { Link } from 'react-router-dom'


export const TopNav = () => (
    <nav className="navbar sticky-top navbar-light bg-white shadow-sm navbar-expand mb-3">
        <div className="container-lg justify-content-start">
            <Link to="/" className="navbar-brand mb-0 h1">Link Universe</Link>

            <div className="navbar-nav">
                <Link to="/campaigns" className="nav-link">
                    Campaigns
                </Link>
                <Link to="/cost" className="nav-link">
                    Costs
                </Link>
            </div>
        </div>
    </nav>
)
