import { useState } from 'react'
import PropTypes from 'prop-types'

import { Table } from '../../Table'
import { Button, Select } from '../../ui-blocks'


export function KeywordSerpUrls({
    links,
    keyword,
    availableKeywords,
    availableBacklinks,
    handleSelectKeyword,
    handleSelectUrl
}) {

    const [showOnlyWithBacklinks, setShowOnlyWithBacklinks] = useState(false)
    const [showOnlyBelongingToDomain, setShowOnlyBelongingToDomain] = useState(false)

    const columns = [
        {
            field: 'id',
            title: '#',
            className: 'text-end'
        }, {
            field: 'keyword',
            title: 'Keyword'
        }, {
            field: 'rankAbsolute',
            title: 'Rank Abs'
        }, {
            field: 'type',
            title: 'Type'
        },
        {
            field: 'url',
            title: 'Backlinks',
            key: 'backlinks',
            render: val => (val in availableBacklinks) ? availableBacklinks[val] : <>&mdash;</>
        }, {
            field: 'url',
            title: 'URL',
            render: (val, column, row) => (row.areKeywordBacklinksAvailable && availableBacklinks[row.url]) ?
                <button className="btn btn-link p-0 lh-1" onClick={() => handleSelectUrl(val)}>
                    {val}
                </button> :
                val
        }
    ]

    const options = getOptionsFromKeywords(availableKeywords)

    let filteredLinks = links

    filteredLinks = (keyword !== '--all')
        ? filteredLinks.filter(link => link.keyword === keyword)
        : filteredLinks

    filteredLinks = showOnlyWithBacklinks
        ? filteredLinks.filter(link => link.areKeywordBacklinksAvailable)
        : filteredLinks

    filteredLinks = showOnlyBelongingToDomain
        ? filteredLinks.filter(link => link.belongsToDomain)
        : filteredLinks


    const isFiltered = (filteredLinks.length !== links.length)

    const count = isFiltered
        ? `${filteredLinks.length} of ${links.length}`
        : filteredLinks.length


    const rowsWithIndex = filteredLinks.map((value, index) => ({ ...value, id: index + 1 }))

    function handleResetFilters() {
        handleSelectKeyword('--all')
        setShowOnlyWithBacklinks(false)
        setShowOnlyBelongingToDomain(false)
    }


    return (<>
        <div className="d-flex gap-3 align-items-center mx-3">
            <div className="d-flex align-items-center">
                <span>Keyword:&nbsp;</span>
                <Select
                    options={options}
                    value={keyword}
                    onChange={e => handleSelectKeyword(e.target.value)}
                />
            </div>

            <div className="form-check form-switch">
                <input
                    className="form-check-input"
                    type="checkbox"
                    id="backlink-toggler"
                    checked={showOnlyWithBacklinks}
                    onChange={() => setShowOnlyWithBacklinks(!showOnlyWithBacklinks)}
                />
                <label className="form-check-label" htmlFor="backlink-toggler">
                    Only with Backlinks
                </label>
            </div>

            <div className="form-check form-switch">
                <input
                    className="form-check-input"
                    type="checkbox"
                    id="belonging-domain-toggler"
                    checked={showOnlyBelongingToDomain}
                    onChange={() => setShowOnlyBelongingToDomain(!showOnlyBelongingToDomain)}
                />
                <label className="form-check-label" htmlFor="belonging-domain-toggler">
                    Only from Main Domain
                </label>
            </div>

            <span>Showing&nbsp;{count}</span>
            {isFiltered && (
                <Button
                    variant="secondary"
                    className="btn-sm"
                    onClick={handleResetFilters}
                >
                    Show All
                </Button>
            )}
        </div>
        <Table columns={columns} rows={rowsWithIndex} />
    </>)
}


KeywordSerpUrls.propTypes = {
    links: PropTypes.array.isRequired,
    keyword: PropTypes.string.isRequired,
    availableKeywords: PropTypes.object.isRequired,
    handleSelectKeyword: PropTypes.func.isRequired,
    handleSelectUrl: PropTypes.func.isRequired
}


function getOptionsFromKeywords(availableKeywords) {
    const keywords = Object.getOwnPropertyNames(availableKeywords)

    return [
        {
            value: '--all',
            label: 'All Keywords'
        },
        ...keywords.map(availableKeyword => ({
            value: availableKeyword,
            label: `${availableKeyword} - ${availableKeywords[availableKeyword]}`
        }))
    ]
}
