import { useState } from 'react'
import PropTypes from 'prop-types'

import { Table } from '../../Table'
import { Button, RadioButtonGroup, Select } from '../../ui-blocks'

import { getSimplifiedUrl, urlCellRenderer } from './urls'


const columns = [
    {
        field: 'id',
        title: '#',
        className: 'text-end'
    }, {
        field: 'Source URL',
        title: 'Source URL',
        render: urlCellRenderer
    }, {
        field: 'Score',
        title: 'Score',
        className: 'text-end'
    }, {
        field: 'Target URL',
        title: 'Target URL',
        render: urlCellRenderer
    }
]


const sortingOptions = [
    { value: 'Source URL', label: 'Source URL' },
    { value: 'Score', label: 'Score' },
    { value: 'Target URL', label: 'Target URL' }
]

const sortingFunctions = {
    'Source URL': (a, b) => String(a['Source URL']).localeCompare(String(b['Source URL'])),
    'Score': (a, b) => b['Score'] - a['Score'],
    'Target URL': (a, b) => String(a['Target URL']).localeCompare(String(b['Target URL'])),
}


export function SerpUrlBacklinks({ backlinks, availableBacklinks, currentBacklink, handleSelectUrl }) {

    const [sortingField, setSortingField] = useState('Source URL')

    const isFiltered = (currentBacklink !== '--all')

    const backlinkWithoutSlash = getUrlWithoutSlash(currentBacklink)

    /** @type {any[]} */
    const rows = (isFiltered)
        ? backlinks.filter(link => getUrlWithoutSlash(link['Target URL']) === backlinkWithoutSlash)
        : backlinks

    const sortedRows = rows.sort(sortingFunctions[sortingField])


    const rowsWithIndex = sortedRows.map((value, index) => ({ ...value, id: index + 1 }))


    const count = (isFiltered)
        ? `${rows.length} of ${backlinks.length}`
        : rows.length


    const backlinksOptions = getOptionsFromBacklinks(availableBacklinks)


    return (<>
        <div className="d-flex gap-3 align-items-center py-1 mx-3">
            <div>
                <span>Target URL</span><br />
                <Select
                    options={backlinksOptions}
                    value={currentBacklink}
                    onChange={e => handleSelectUrl(e.target.value)}
                    className="form-select-sm"
                />
            </div>
            <span>Showing&nbsp;{count}</span>
            <div>
                {isFiltered && (
                    <Button
                        variant="secondary"
                        className="btn-sm"
                        onClick={() => handleSelectUrl('--all')}
                    >
                        Show All
                    </Button>
                )}
            </div>

            <div className="ms-auto">
                <span>Sorting</span><br />
                <div>
                    <RadioButtonGroup
                        id="sorting"
                        className="btn-group-sm"
                        options={sortingOptions}
                        value={sortingField}
                        onChange={setSortingField}
                    />
                </div>
            </div>
        </div>
        <Table columns={columns} rows={rowsWithIndex} />
    </>)
}


SerpUrlBacklinks.propTypes = {
    backlinks: PropTypes.array.isRequired,
    availableBacklinks: PropTypes.object.isRequired,
    currentBacklink: PropTypes.string.isRequired,
    handleSelectUrl: PropTypes.func.isRequired
}


function getOptionsFromBacklinks(availableBacklinks) {
    const backlinks = Object.getOwnPropertyNames(availableBacklinks)

    return [
        {
            value: '--all',
            label: 'All Backlinks'
        },
        ...backlinks
            .filter(backlink => !!availableBacklinks[backlink])
            .map(backlink => ({
                value: backlink,
                label: `${getSimplifiedUrl(backlink)} [${availableBacklinks[backlink]}]`
            }))
    ]
}


/**
 * @param {string} url
 * @returns {string}
 */
function getUrlWithoutSlash(url) {
    return url.endsWith('/') ? url.substr(0, url.length - 1) : url
}
