import React from 'react'
import PropTypes from 'prop-types'

import { Button } from '../Button'
import { Loader } from '../Loader'


const loaderSize = {
    height: '1em',
    width: '1em'
}


const IconPlaceholder = (<span className="d-inline-block" style={loaderSize}></span>)


export const SubmitButton = ({
    isLoading = false,
    isValid = true,
    fullWidth = false,
    className = '',
    children,
    ...props
}) => {

    let mainIcon = IconPlaceholder


    if (isLoading) {
        mainIcon = (<Loader style={loaderSize} />)
    }


    return (
        <Button
            disabled={isLoading || !isValid}
            className={`px-3 ${className}${fullWidth ? ' w-100' : ''}`}
            {...props}>
            <>
                {mainIcon}
                <span className="mx-2">{children}</span>
                {IconPlaceholder}
            </>
        </Button>
    )
}

SubmitButton.propTypes = {
    className: PropTypes.string,
    fullWidth: PropTypes.bool,
    isLoading: PropTypes.bool,
    isValid: PropTypes.bool
}
