/**
 * @param {string} endpoint
 * @param {object} endpoint
 * @param {string} method
 */
export async function apiFetch(endpoint, payload = null, method = 'GET') {

    const options = payload ? {
        method: (method === 'GET') ? 'POST' : method,
        body: JSON.stringify(payload)
    } : {}


    const headers = {
        'Content-Type': 'application/json'
    }


    return new Promise(resolve => {
        fetch(`${process.env.REACT_APP_API_URL}${endpoint}`, {
            method,
            ...options,
            headers
        })
            .then(async response => {
                if (response.ok) {
                    resolve(response.json())
                    return
                }
            })
    })

}
