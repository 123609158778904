export const getSimplifiedUrl = url =>
    !!url ? String(url).replace(/^(https?:\/\/)?(www\.)?/, '') : ''


export const urlCellRenderer = url => {
    let urlParts

    try {
        urlParts = getPathParts(url)
    } catch (e) {
        return String(url)
    }


    const { subdomain, domain, path, name, search } = urlParts

    return (
        <a href={url} title={url} target="_blank" rel="noreferrer">
            {subdomain}
            <strong>
                {domain}
            </strong>
            <span className="opacity-75">
                {path}
            </span>
            {name}
            {search}
        </a>
    )
}


export function getDomainWithoutSubdomain(hostname) {
    /** @type string[] */
    const urlParts = hostname.split('.')

    const hasCountry = (urlParts.length > 3) && (urlParts[urlParts.length - 1].length === 2)

    const partsToInclude = Math.max(urlParts.length - 2, 2) + (hasCountry ? 1 : 0)


    return urlParts
        .slice(- partsToInclude)
        .join('.')
}


/** @param {string} url */
function getPathParts(url) {
    const { hostname, pathname, search } = new URL(url)

    const domain = getDomainWithoutSubdomain(hostname)

    const subdomain = hostname
        .substring(0, hostname.length - domain.length)
        .replace(/^(www\.)?/, '')


    const lastPathSlashPos = pathname.lastIndexOf('/') + 1

    return {
        subdomain,
        domain,
        pathname,
        path: pathname.slice(0, lastPathSlashPos),
        name: pathname.slice(lastPathSlashPos),
        search
    }
}
