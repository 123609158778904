export const getHeaderCels = columns =>
    getNormalizedColumns(columns).map(column => (
        <th key={column.key || column.field} className={`small text-white lh-1 ${column.className}`} scope="col">
            {column.title}
        </th>
    ))


export const getRowCels = (row, columns) =>
    getNormalizedColumns(columns).map(column => (
        <td key={column.key || column.field} className={`${column.className} ${column.cellClassName}`}>
            {column.render(row[column.field], column, row)}
        </td>
    ))


function getNormalizedColumns(columns) {
    return columns.map(column => ({
        field: '',
        title: '',
        render: val => val,
        className: '',
        cellClassName: '',
        ...column
    }))
}
