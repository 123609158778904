import { Link, useNavigate } from 'react-router-dom'

import { useForm } from '../helpers'

import { CampaignFields } from '../components/Campaigns/CampaignFields'
import { SubmitButton } from '../components/ui-blocks'

import { campaignFields } from '../components/Campaigns/campaign-fields'


export function CreateCampaign() {
    const [form] = useForm(campaignFields)
    const navigate = useNavigate()

    const submit = () => {
        navigate(`/campaigns/${form.values.domain}`)
    }


    if (!Object.keys(form.values).length) {
        return null
    }


    return <div className="container-lg">

        <div className="mb-4">
            <Link to="/campaigns">Campaigns</Link>
            <h1>Campaign Creation</h1>
        </div>

        <CampaignFields fields={form.fields} />

        <div className="mt-3 text-end">
            <SubmitButton onClick={submit} isValid={form.isValid}>
                Create Campaign
            </SubmitButton>
        </div>

    </div>

}
