import { useEffect, useState } from 'react'

import { Cost } from '../components/Cost/Cost'
import { getCost } from '../services'


export function ApiCost() {
    const [transactions, setTransactions] = useState()


    useEffect(() => {
        getCost()
            .then(result => setTransactions(result.transactions))
    }, [])


    if (!transactions) {
        return null
    }


    return (
        <div className="container-lg">
            <h1>Costs</h1>

            <Cost transactions={transactions} />
        </div>
    )
}
