import React from 'react'
import PropTypes from 'prop-types'


export const Input = ({ isInvalid = false, isDirty = false, className = '', ...props }) => {
    let inputClasses = `form-control ${className}`

    if (isInvalid) {
        inputClasses += ' is-invalid'
    }

    if (!isInvalid && isDirty) {
        inputClasses += ' border-success'
    }

    return (
        <div className="input-group">

            <input className={inputClasses} {...props} />

        </div>
    )
}


Input.propTypes = {
    isInvalid: PropTypes.bool,
    isDirty: PropTypes.bool
}
