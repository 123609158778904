import React from 'react'
import PropTypes from 'prop-types'


export const RadioButtonGroup = ({ options = [], value, onChange, id = '', className = '', ...props }) => (
    <div className={`btn-group ${className}`} role="group" {...props}>
        {
            options.map(({ value: optionValue, label }) => {
                const optionId = `btn-${id}-${optionValue}`

                return (
                    <React.Fragment key={optionValue}>
                        <input type="radio"
                            autoComplete="off"
                            checked={optionValue === value}
                            className="btn-check"
                            id={optionId}
                            name={id}
                            onChange={e => onChange(e.target.value)}
                            value={optionValue}
                        />
                        <label className="btn btn-outline-secondary" htmlFor={optionId}>{label}</label>
                    </React.Fragment>
                )
            })
        }
    </div >
)


RadioButtonGroup.propTypes = {
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func,
    id: PropTypes.string.isRequired
}
