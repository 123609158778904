import React from 'react'
import PropTypes from 'prop-types'

import { InputField } from '../../../helpers/Form'


const isInputField = inputField => (inputField instanceof InputField)

const isRequired = inputField => {
    if (!isInputField(inputField)) {
        return false
    }

    const validatorNames = inputField.validators.map(validator => validator.name)

    return validatorNames.includes('isRequired')
}


export const FormField = ({
    label,
    labelSuffix = null,
    isDirty = false,
    isInvalid = false,
    inputField = {},
    children,
    ...props
}) => {
    const inputFieldProps = isInputField(inputField) ? inputField.getStatusProps() : {}

    const labelProps = children.props?.id ? { htmlFor: children.props.id } : {}

    let labelClassName = ''

    if (isDirty || inputFieldProps.isDirty) {
        labelClassName = 'fw-bold'
    }

    if (isInvalid || inputFieldProps.isInvalid) {
        labelClassName = 'fw-bold text-danger'
    }


    const isRequiredIndicator = isRequired(inputField) ? ' *' : ''


    return (
        <div {...props}>
            {(label || labelSuffix) && (
                <div className="d-flex justify-content-between">
                    {label &&
                        <label className={`form-label d-block ${labelClassName}`} {...labelProps}>
                            {label}{isRequiredIndicator}
                        </label>
                    }
                    {labelSuffix && (<span>{labelSuffix}</span>)}
                </div>
            )}
            {children}
        </div>
    )
}


FormField.propTypes = {
    label: PropTypes.string.isRequired,
    labelSuffix: PropTypes.node,
    isDirty: PropTypes.bool,
    isInvalid: PropTypes.bool,
    inputField: PropTypes.instanceOf(InputField)
}
