import { getDomainWithoutSubdomain } from './Reports/urls'


export const getKeywordUrlMarkedWithDomain = (urls, domain) =>
    urls.map(entry => ({
        ...entry,
        belongsToDomain: isUrlFromDomain(entry.url, domain)
    }))


function isUrlFromDomain(url, domain) {
    const { hostname } = new URL(url)
    const urlDomain = getDomainWithoutSubdomain(hostname)

    return urlDomain === domain
}
