import { FormField, Input } from '../ui-blocks/forms'


export function CampaignFields({ fields }) {

    return <>
        <FormField label="Main Domain">
            <Input
                {...fields.domain?.getStatusProps()}
                {...fields.domain?.getProps()}
                className="form-control-lg rounded-2"
            />
        </FormField>
    </>
}
