import { Navigate, Routes, Route } from 'react-router-dom'

import { TopNav } from './components/TopNav'
import { ApiCost, Campaigns, CreateCampaign, CampaignDetail } from './sections'


export const App = () => (
    <>
        <TopNav />
        <Routes>
            <Route path="/cost" element={<ApiCost />} />
            <Route path="/campaigns/create" element={<CreateCampaign />} />
            <Route path="/campaigns/:domain" element={<CampaignDetail />} />
            <Route path="/campaigns" element={<Campaigns />} />
            <Route path="*" element={<Navigate to="/campaigns" replace />} />
        </Routes>
    </>
)
